import logo from './logo.svg';
import './index.css'
import './App.css';
import { 
  ProductCardCollection,
  NavBar
} from './ui-components';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';



function App() {




  return (
    <div className="App">
      <h1>Coming Soon</h1>
    </div>
  );
}

export default App;
